import Repository from "../Repository";
import Establishement from "gaia-sdk-js/lib/src/class/Establishment/Establishment";
import ProfileEstablishment from "gaia-sdk-js/lib/src/class/ProfileEstablishment";
import {Autowire, DependencyInjector, DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import Config from "../../Config";
import {AuthManager} from "../../class/Util/AuthManager";

export default class MenuRepository extends Repository {
	constructor() {
		super();
	}

	static getSidebarMenu(currentEstablishment: Establishement, connectedProfile: ProfileEstablishment): Promise<{
		top: {name: string, pageTitle: string, url: string, icon: string}[],
		events: {name: string, pageTitle: string, url: string, icon: string}[],
		bar: {name: string, pageTitle: string, url: string, icon: string}[],
		bottom: {name: string, pageTitle: string, url: string, icon: string}[]
	}> {

		//Todo : supprimer le any.
		let config: any = DependencyInjectorInstance().getInstance(Config);

		let menu: {
			top: {name: string, pageTitle: string, url: string, icon: string}[],
			events: {name: string, pageTitle: string, url: string, icon: string}[],
			bar: {name: string, pageTitle: string, callback?: Function, url: string, icon: string}[],
			bottom: {name: string, pageTitle: string, callback?: Function, url: string, icon: string}[]
		} = { top: [], events: [], bar: [], bottom: [] };

		menu.top.push({name: "Accueil", pageTitle: 'index', url: "/index", icon: "fa-regular fa-house-blank"});

		if (currentEstablishment.permissions.statistics) {
			// menu.push({name: "Évènements", url: "#products/events/events", icon: "icon-speaker"});
			menu.top.push({name: "Statistiques", pageTitle: 'stats', url: "/statistics", icon: "fa-solid fa-chart-mixed"});
		}

		if (currentEstablishment.permissions.orders && connectedProfile.permissions.orders_read) {
			menu.top.push({name: "Commandes", pageTitle: 'orders', url: "/orders", icon: "fa-regular fa-cart-shopping"});
		}

		if (currentEstablishment.permissions.product_type_event && connectedProfile.permissions.products_read) {
			// menu.push({name: "Évènements", url: "#products/events/events", icon: "icon-speaker"});
			menu.events.push({name: "Liste des évènements", pageTitle: 'events', url: "/products/events", icon: "fa-regular fa-receipt"});
		}

		if (
			currentEstablishment.permissions.ticketing
			&& connectedProfile.permissions.ticketing_read
			&& connectedProfile.permissions.ticketing_consume
		) {
			menu.events.push({name: "Contrôle d'accès", pageTitle: 'delivery', url: "/delivery", icon: "fa-regular fa-qrcode"});
		}

		if (
			currentEstablishment.permissions.establishment_shared_resource
			&& connectedProfile.permissions.establishment_shared_resource_write
		) {
			menu.events.push({name: "Scan des cartes de visite", pageTitle: 'shared-resource-scan', url: "/shared-resource/scan", icon: "fa-regular fa-barcode-read"});
		}

		if (currentEstablishment.permissions.schedule_ticket && connectedProfile.permissions.products_write) {
			// menu.push({name: "Évènements", url: "#products/events/events", icon: "icon-speaker"});
			menu.events.push({name: "Calendrier", pageTitle: 'calendar', url: "/event-schedule", icon: "fa-regular fa-calendar"});
		}

		if (
			currentEstablishment.permissions.bar
			&& connectedProfile.permissions.products_write
			&& connectedProfile.permissions.categories_write
			&& connectedProfile.permissions.products_read
		) {
			// menu.push({name: "Évènements", url: "#products/events/events", icon: "icon-speaker"});
			menu.bar.push({name: "Liste des produits", pageTitle: 'products', url: "/products/bar", icon: "fa-regular fa-martini-glass-citrus", callback: () => { }});
			menu.bar.push({name: "Caisse enregistreuse", pageTitle: 'pos-bar', url: config.pl_serviceUrl + "posBar.html", callback: () => { window.location.href = config.pl_serviceUrl + 'posBar.html' }, icon: "fa-regular fa-store"});
		}
		// if (currentEstablishement.permissions.statistics) {
		// 	menu.push({name: "Statistiques", url: "/statistics", icon: "signal icon"});
			// menu.push({name: "Statistiques", url: "#stats/statistics", icon: "icon-activity"});
		// }

		if (
			currentEstablishment.permissions.establishment_customers
			&& connectedProfile.permissions.establishment_customers_read
			&& connectedProfile.permissions.establishment_identity_customers_read
		) {
			menu.bottom.push({name: "Clients", pageTitle: 'customers', callback: () => {}, url: "/customers", icon: "fa-regular fa-circle-user"});
			// menu.push({name: "Clients", url: "#parameters/customers", icon: "icon-clipboard"});
		}

		menu.bottom.push({name: "Virements", pageTitle: 'payouts', callback: () => {}, url: "/payouts", icon: "fa-regular fa-arrow-right-arrow-left"});
		// menu.push({name: "Clients", url: "#parameters/customers", icon: "icon-clipboard"});

		menu.bottom.push({name: "Paramètres", pageTitle: 'parameters', callback: () => {}, url: "/parameters/", icon: "fa-regular fa-gear"});
		menu.bottom.push({name: "Déconnexion", pageTitle: '', url: "", callback: (event : MouseEvent) => {
			this.disconnect();
			event.preventDefault();
			event.stopImmediatePropagation();
		}, icon: "fa-regular fa-right-from-bracket"});

		return Promise.resolve(menu)
	};

	static disconnect() {
		AuthManager.disconnect();
	}

	static getSidebarCounterEvent(currentEstablishement: Establishement): Promise<any> {
		let menu: Array<any> = [];
		menu.push({name: "Guichet", url: "/index", icon: "home icon"});
		menu.push({name: "Commandes", url: "/index", icon: "home icon"});
		menu.push({name: "Rapports", url: "/index", icon: "home icon"});
		menu.push({name: "Réglages", url: "/reglage", icon: "home icon"});
		menu.push({name: "Plein écran", url: "/index", icon: "expand icon"});
		menu.push({name: "Retour", url: "/products/events", icon: "home icon"});
		return Promise.resolve(menu)
	};
}