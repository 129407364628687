import Vue from "vue";
import {VueClass, VueVar} from "horizon-js-front-sdk/lib/VueAnnotate";
import topbar from "./topbar.html";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import AppStateListener from "../../class/Util/AppStateListener";
import Establishement from "gaia-sdk-js/lib/src/class/Establishment/Establishment";
import EstablishmentRepository from "../../modelRepositories/EstablishmentRepository";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import ProfileEstablishment from "gaia-sdk-js/lib/src/class/ProfileEstablishment";
import Session from "../../model/Session";

@VueClass()
export class Topbar extends Vue {
	@VueVar() currentEstablishment!: Establishement;
	@VueVar() userEstablishments: ProfileEstablishment[] = [];
	@VueVar() hidden: boolean = false;

	@Autowire(EstablishmentRepository) establishmentRepository!: EstablishmentRepository;
	@Autowire(AppStateListener) appStateListener!: AppStateListener;

	constructor(container: string, vueConstructor: any = null) {
		super(vueConstructor);

		this.hidden = this.appStateListener.hiddenNavigationInterfaces;

		this.establishmentRepository.getCurrent().then((establishement: Establishement | null) => {
			if(establishement)
				this.currentEstablishment = establishement;
		});

		this.establishmentRepository.getUserEstablishments(AuthenticationManager.getUserUid()).then((establishments: ProfileEstablishment[]) => {
			this.userEstablishments = establishments;
			Session.setUserEstablishments(establishments);
		});

		this.appStateListener.listen((event: {type: string, value: any}) => {
			if(event.type === 'hiddenNavigationInterfaces') {
				this.hidden = event.value;
			}
		})
	}

	getCurrentEstablishmentProfile() {
		if(this.currentEstablishment === null) {
			return null;
		}
		for(let establishment of this.userEstablishments) {
			if(establishment.establishmentUid === this.currentEstablishment.uid) {
				return establishment;
			}
		}
		return null;
	}

	showMenu() {
		this.appStateListener.setOpenedSidebar(true);
	}

	switchEstablishment() {
		this.appStateListener.switchEstablishment();
	}
}

export class PageWithTopbar {
	elementName: string = '';

	constructor(elementName = '#top-bar') {
		this.loadSidebar();
		this.elementName = elementName;
	}

	loadSidebar() {
		$("#top-bar").html(topbar);
		new Topbar('#top-bar');
	}
}
