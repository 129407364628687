import User from "./User";
import Address from "../Address";

export default class UserNatural extends User {
	public lastname!: string;
	public firstname!: string;
	public dateOfBirth!: string;

	public email!: string;

	public countryOfResidence!: string;
	public nationality!: string;

	/** Address **/
	public addressUid!: string | null;
	public address!: Address | null;

	/** Validation **/
	public kycLevel!: string;
	public incomeRange!: string | null;
	public income_range!: string | null;
	public occupation!: string | null;


	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		UserNatural.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (prop === 'address') {
					instance[prop] = new Address(json[prop]);
				} else {
					instance[prop] = json[prop];
				}
			}
		}
	}

	exportToJson() {
		let JSONUserNatural: any = {};
		JSONUserNatural['addressUid'] = this.addressUid;
		JSONUserNatural['countryOfResidence'] = this.countryOfResidence;
		JSONUserNatural['dateOfBirth'] = this.dateOfBirth;
		JSONUserNatural['email'] = this.email;
		JSONUserNatural['firstname'] = this.firstname;
		JSONUserNatural['incomeRange'] = this.incomeRange;
		JSONUserNatural['income_range'] = this.income_range;
		JSONUserNatural['kycLevel'] = this.kycLevel;
		JSONUserNatural['lastname'] = this.lastname;
		JSONUserNatural['nationality'] = this.nationality;
		JSONUserNatural['occupation'] = this.occupation;
		JSONUserNatural['userUid'] = this.userUid;
		return JSONUserNatural;
	}
}