import {SidebarVue} from "./pages/modules/sidebar";
import FileRepository from "./modelRepositories/FileRepository";
import {Logger} from "horizon-js-front-sdk/lib/Logger";
import {DependencyInjector, DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import {Router} from "horizon-js-front-sdk/lib/Router";
import Cleave from 'vue-cleave-component';
import './libs/Polyfill/String';
import "jquery";
import "./assets/css/dashboard.css";
import "./assets/css/gaia.css";
import "./libs/HorizonCss/horizon.css";
import "./libs/HorizonCss/fix-fomantic.css";
import "fomantic/src/semantic.css";
import "fomantic/src/semantic.js";
import "horizon-js-front-sdk/lib/xdomain.min.js";
import "font-awesome-sdk/src/V6/css/pro-v4-font-face.min.css";
import "font-awesome-sdk/src/V6/css/pro-v4-shims.min.css";
import "font-awesome-sdk/src/V6/css/pro.min.css";
import Config from "./Config";
import Vue from "vue";
import moment from "moment";
import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";
import {SignupManager} from "./class/Util/SignupManager";
import {PageWithTopbar} from "./pages/modules/topbar";
import {Native} from "@groupk/native-bridge";
import {NativeMocks} from "@groupk/native-bridge/dist/nativeMocks";

//setting logger on info
Logger.level = Logger.INFO;
let injector: DependencyInjector = DependencyInjectorInstance();

let config: Config = new Config();
injector.register(Config, config);

async function initApp() {
	Vue.use(Cleave);
	moment.locale('fr');

	try {
		Sentry.init({
			dsn: config.sentryDsn,
			environment: config.sentryEnvironment,
			integrations: [new Integrations.BrowserTracing(), new Sentry.Integrations.GlobalHandlers({
				onerror: true,
				onunhandledrejection: true
			}), new Sentry.Integrations.TryCatch({eventTarget: false})],

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,
		});
	} catch (err) {
		console.error(err);
	}

	if (config.version === "develop") {
		(new Native.SystemInfo()).getAppInfo().then(() => {
		}).catch((e: any) => {
			if (e === 'non_native_platform') {
				let nativeInterface = NativeMocks.NativeInterface.register();
				nativeInterface.addModule(new NativeMocks.BarcodeExternal(true));
				nativeInterface.addModule(new NativeMocks.Gear());
			}
		});

	}

	injector.register(Config, config);

	if (window.location.pathname === '/index.html' || window.location.pathname === '/') {
		let oldRoute = Router.extractPageFromUrl({
			type: 'HASHBANG',
			config: {registerGlobalInterceptor: false, prefix: '#!'}
		});
		if (oldRoute !== 'index')
			window.location.href = '/' + oldRoute + '.html';
	}

	let router = new Router('/pages/', {
		type: 'URL',
		config: {
			suffixes: ['.html'],
			prefix: '/',
			registerGlobalInterceptor: true
		}
	}, 'page');


	let uuidRegex = /([0-9a-f]{8}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{12})/;

	router.addRoute({location: 'empty', loader: () => import('./pages/empty')});
	router.addRoute({location: 'index', loader: () => import('./pages/index')});
	router.addRoute({location: 'auth', loader: () => import('./pages/auth')});
	router.addRoute({regex: new RegExp(/signup\//.source + uuidRegex.source), loader: () => import('./pages/signup')});
	router.addRoute({location: 'signup', loader: () => import('./pages/signup')});
	router.addRoute({location: 'statistics', loader: () => import('./pages/stats/statistics')});
	router.addRoute({location: 'stats/event', loader: () => import('./pages/stats/eventStatistics')});
	router.addRoute({location: 'event-schedule', loader: () => import('./pages/parameters/eventSchedule')});

	router.addRoute({location: 'products/ticketing', loader: () => import('./pages/products/ticketing/parameters')});

	// Product Classic
	router.addRoute({location: 'product', loader: () => import('./pages/products/product')});
	router.addRoute({location: 'products', loader: () => import('./pages/products/products')});
	// Product Event
	router.addRoute({location: 'products/events', loader: () => import('./pages/products/events/events')});
	router.addRoute({location: 'products/event', loader: () => import('./pages/products/events/event')});
	router.addRoute({location: 'products/museum', loader: () => import('./pages/products/events/museum')});

	// Product bar & Nightclub
	router.addRoute({location: 'products/bar', loader: () => import('./pages/products/bars')});

	router.addRoute({location: 'customers', loader: () => import('./pages/parameters/customers')});
	router.addRoute({location: 'payouts', loader: () => import('./pages/parameters/payouts')});
	router.addRoute({location: 'orders', loader: () => import('./pages/order/orders')});
	router.addRoute({location: 'delivery', loader: () => import('./pages/delivery/delivery-event')});
	router.addRoute({regex: new RegExp(/parameters\//.source + /.*/.source), loader: () => import('./pages/parameters/index')});
	router.addRoute({regex: new RegExp(/admin\//.source + /.*/.source), loader: () => import('./pages/admin/index')});
	router.addRoute({location: 'shared-resource/scan', loader: () => import('./pages/sharedResourceScan')});
	router.addRoute({location: 'editor', loader: () => import('./pages/products/ticketing/editor')});

	let systemInfo: any = injector.getInstance(Native.SystemInfo);
	injector.register(Native.SystemInfo, systemInfo);
	systemInfo.listenUriTriggeredEvents((uri) => {
		window.location.href = '/' + uri.split('//')[1];
	}).catch(() => {

	});

	DependencyInjectorInstance().register(Router, router);
	await router.updateCurrentPageFromCurrentLocation();

	let loadingScreenNative: Native.LoadingScreen | any = DependencyInjectorInstance().getInstance(Native.LoadingScreen);
	loadingScreenNative.disable().catch(() => {});

	let signupManager: SignupManager = new SignupManager();
	let isAccountFullyConfigured = await signupManager.isAccountFullyConfigured();
	if (!isAccountFullyConfigured) {
		await router.changePage('signup');
	} else {

		let topbar: PageWithTopbar = new PageWithTopbar('#topbar');
		injector.register(PageWithTopbar, topbar);

		let sidebarVue = new SidebarVue('#sidebar');
		injector.register(SidebarVue, sidebarVue);

		let fileRepository: FileRepository = new FileRepository();
		injector.register(FileRepository, fileRepository);

		let nativeGear: Native.Gear = new Native.Gear();
		injector.register(Native.Gear, nativeGear);
	}
}

if (!config.isValid()) {
	config.getFromServer().then(function (JSONConfig: any) {
		config = new Config(JSONConfig);
		let xDomainParameter: any = {};

		let plDomain = new URL('/', config.gaiaGateway_apiUrl).href;
		if (plDomain) {
			let nddWithoutSlashAtTheEnd: string = '';
			if (plDomain.charAt(plDomain.length - 1) === '/') {
				nddWithoutSlashAtTheEnd = plDomain.substring(0, plDomain.length - 1);
			}
			xDomainParameter[nddWithoutSlashAtTheEnd] = "/api/cors";
		}
		xdomain.slaves(xDomainParameter);

		let storedVersion = window.localStorage.getItem('version');
		if (storedVersion !== null) {
			window.location.reload();
		}
		initApp();
	}).catch(function (error) {
		console.log("Error : Index.ts " + error);
	});
} else {
	initApp();
}
