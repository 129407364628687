import Model from "./Model";
import Company from "./Company";

export default class Address extends Model {
	public line1: string = '';
	public line2: string = '';
	public city: string = '';
	public postalCode: string = '';
	public country: string = 'FR';

	private _company!: Company;

	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Address.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					if (prop === 'company') {
						instance[prop] = new Company(json[prop]);
					} else {
						instance[prop] = json[prop];
					}
				}
			}
		}
	}

	get company(): Company {
		if (this._company === undefined || this._company === null)
			this._company = new Company();
		return this._company;
	}

	set company(value: Company) {
		this._company = value;
	}

	exportToJson() {
		let JSONAddress: any = {};
		JSONAddress['line1'] = this.line1;
		JSONAddress['line2'] = this.line2;
		JSONAddress['city'] = this.city;
		JSONAddress['postalCode'] = this.postalCode;
		JSONAddress['country'] = this.country;
		if (this._company) {
			JSONAddress['company'] = this._company.exportToJson();
		}
		return JSONAddress;
	}
}