import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import Config from "../Config";

export default class Repository {
	public static GET = 'GET';
	public static POST = 'POST';
	public static PUT = 'PUT';
	public static DELETE = 'DELETE';

	@Autowire(Config) config!: Config;

	constructor() {
	}

	protected request(url: string, data: any = null, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', requireAuth = true, specificParameters: any = {}): any {
		let request: any = {
			url: url,
			method: method,
			headers: {}
		};

		if (method == 'GET') {
			request.data = undefined;
		} else {
			request.data = typeof data !== 'string' ? JSON.stringify(data) : data;
			request.contentType = "application/json; charset=utf-8";
		}


		if (AuthenticationManager.isConnected()) {
			request.headers['authorization'] = AuthenticationManager.getAuthentificationHeader();
		} else {
			if (requireAuth) {
				let currentUrl = window.location.href;
				window.location.href = '/signup';
			}
		}

		let responseType : 'json'|'arraybuffer' = 'json';

		for (let key in specificParameters) {
			if (key === 'arraybuffer' && specificParameters[key] === true) {
				responseType = 'arraybuffer';
			} else
				request[key] = specificParameters[key];
		}

		return new Promise(function (resolve, reject) {
			let xhr = new XMLHttpRequest();
			xhr.responseType = responseType;

			xhr.onload = function () {
				if(xhr.responseType === 'json') {
					xhr['responseJSON'] = xhr.response;
				}
				if(xhr.status >= 200 && xhr.status <= 299) {
					resolve(xhr.response);
				} else {
					reject(xhr)
				}
			};
			xhr.onerror = function () {
				if(xhr.responseType === 'json') {
					xhr['responseJSON'] = xhr.response;
				}
				reject(xhr);
			};

			xhr.open(request.method, request.url, true);
			if(request.contentType)
				xhr.setRequestHeader('Content-Type', request.contentType);
			for (let header in request.headers) {
				xhr.setRequestHeader(header, request.headers[header]);
			}
			xhr.send(request.data);

		});
	};

	get apiUrl() {
		return this.config.gaiaGateway_apiUrl;
	}

	get uploadUrl() {
		return new URL('/', this.config.gaiaGateway_apiUrl).href + 'uploads/';
	}
}

export type error_api = {
	status: number,
	statusText: string,
	responseJSON?: {
		conflict_object_type?: "ORDER_PURCHASE"|"ORDER"|"ORDER_PAYMENT",
		conflict_uuid?: Uuid,
		error?: "uuid_conflict"|"invalid_data"
		error_description?: string
	}
}