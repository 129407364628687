export default class AppStateListener {
	public pageTitle: { raw: string, formatted: string } = { raw: '', formatted: '' };
	public openedSidebar: boolean = false;
	public minimizedSidebar: boolean = false;
	public hiddenNavigationInterfaces: boolean = false;
	public fullScreen: boolean = false;

	private listeners: Function[] = [];

	constructor() {}

	public listen(callback: Function) {
		this.listeners.push(callback);
	}

	setPageTitle(value: { raw: string, formatted: string }) {
		this.pageTitle = value;
		for(let callback of this.listeners) {
			callback({
				type: 'pageTitle',
				value: value
			});
		}
	}

	setOpenedSidebar(value: boolean) {
		this.openedSidebar = value;
		for(let callback of this.listeners) {
			callback({
				type: 'openedSidebar',
				value: value
			});
		}
	}

	setMinimizedSidebar(value: boolean) {
		this.minimizedSidebar = value;
		for(let callback of this.listeners) {
			callback({ type: 'hiddenNavigationInterfaces', value: false });
			callback({ type: 'minimizedSidebar', value: value });
		}
	}

	setHiddenNavigationInterfaces(value: boolean) {
		this.hiddenNavigationInterfaces = value;
		for(let callback of this.listeners) {
			callback({
				type: 'hiddenNavigationInterfaces',
				value: value
			});
		}
	}

	switchEstablishment() {
		for(let callback of this.listeners) {
			callback({
				type: 'switchEstablishment',
				value: null
			});
		}
	}



	setFullScreen(): boolean {
		let elem: any = document.documentElement;
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) { /* Firefox */
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) { /* IE/Edge */
			elem.msRequestFullscreen();
		}
		this.fullScreen = true;
		return true;
	}

	exitFullScreen(): boolean {
		let doc: any = document;
		if (doc.exitFullscreen) {
			doc.exitFullscreen();
		} else if (doc.mozCancelFullScreen) { /* Firefox */
			doc.mozCancelFullScreen();
		} else if (doc.webkitExitFullscreen) { /* Chrome, Safari and Opera */
			doc.webkitExitFullscreen();
		} else if (doc.msExitFullscreen) { /* IE/Edge */
			doc.msExitFullscreen();
		}
		this.fullScreen = false;
		return false;
	}

	/** View in fullscreen **/
	toggleFullScreen(): boolean {
		if (!this.fullScreen) {
			return this.setFullScreen();
		} else {
			return this.exitFullScreen();
		}
	}
}