import Repository from "./Repository";
import UserLegal from "../model/User/UserLegal";
import UserNatural from "../model/User/UserNatural";
import {UuidType} from "../libs/Uuid";
import KYC from "../model/User/KYC";
import Task from "../model/Task";
import User from "gaia-sdk-js/lib/src/class/Identity/User";
import Profile from "gaia-sdk-js/lib/src/class/Identity/Profile";
import {Company} from "gaia-sdk-js/lib/src/class/Identity/Company";
import ProfileEstablishment from "gaia-sdk-js/lib/src/class/ProfileEstablishment";
import Order from "gaia-sdk-js/lib/src/class/Order/Order";
import Cart from "gaia-sdk-js/lib/src/class/Order/Cart";
import OrderTaskItem from "gaia-sdk-js/lib/src/class/Order/OrderTaskItem";
import {CharacteristicType} from "gaia-sdk-js/lib/src/class/Identity/Resource";
import Characteristic from "gaia-sdk-js/lib/src/class/Identity/Characteristic";
import PlatformUser from "../model/Customer/PlatformUser";

export default class UserRepository extends Repository {
	constructor() {
		super();
	}

	public get(uid: Uuid): Promise<UserNatural | UserLegal> {
		return this.request(this.apiUrl + 'v1/Users/' + uid + '/', '', 'GET').then(function (user: any) {
			if (user.businessName) {
				return new UserLegal(user);
			} else {
				return new UserNatural(user);
			}

		});
	};

	public getProfile(uid: Uuid): Promise<Profile> {
		return this.request(this.config.identityGateway_apiUrl + 'v1/Profiles/' + uid + '/', '', 'GET').then((JSONProfile: any) => {
			return new Profile(JSONProfile);
		});
	};

	public getCredential(uid: Uuid): Promise<Array<ProfileEstablishment>> {
		return this.request(this.apiUrl + 'v1/Users/' + uid + '/Credentials/', '', 'GET').then(function (credentials: Array<any>) {
			return credentials;
		});
	};

	public createOrder(uidOrGhost: Uuid | boolean, data: Cart): Promise<Array<Order>> {
		let uid: Uuid;
		if (typeof uidOrGhost === "boolean") {
			uid = UuidType.GHOST;
		} else {
			uid = uidOrGhost;
		}

		return this.request(this.apiUrl + 'v1/Users/' + uid + '/Orders/', data, 'POST').then(function (JSON: any) {
			let returnOrders: Array<Order> = [];
			for (let order of JSON) {
				returnOrders.push(new Order(order));
			}
			return returnOrders;

		});
	};

	public createOrders(uidOrGhost: Uuid | boolean, data: Array<OrderTaskItem>): Promise<Task> {
		let uid: Uuid;
		if (typeof uidOrGhost === "boolean") {
			uid = UuidType.GHOST;
		} else {
			uid = uidOrGhost;
		}

		let dataInJson: any = [];
		for (let dt of data) {
			dataInJson.push(dt.exportToJson());
		}

		return this.request(this.apiUrl + 'v1/Users/' + uid + '/Orders/', dataInJson, 'POST').then(function (JSON: any) {
			return new Task(JSON);
		});
	};

	public createUserNatural(establishmentUid: string, user: PlatformUser | User, customData = {}): Promise<PlatformUser> {
		let raw = user.exportToJson(false);
		Object.assign(raw, customData);
		return this.request(this.apiUrl + 'v1/IdentityGateway/Establishments/' + establishmentUid + '/Customers/', raw, "POST", false).then((JSONUser: any) => {
			return new PlatformUser(JSONUser);
		}).catch(function (data: any) {
			return data;
		});
	}

	public getProfileResource(establishmentUid: string, profileUid: string): Promise<User | Company> {
		return this.request(this.apiUrl + 'v1/IdentityGateway/Establishments/' + establishmentUid + '/Customers/' + profileUid + '/', null, "GET", true).then((JSONResource: any) => {
			if (JSONResource.type === 'COMPANY') {
				return new Company(JSONResource);
			} else {
				return new User(JSONResource);
			}
		}).catch(function (data: any) {
			return data;
		});
	}

	public updateUserNatural(establishmentUid: string, user: PlatformUser | User | Company, updateEmail: boolean = false): Promise<PlatformUser | User | Company> {
		let rawUser = user.exportToJsonEditable();

		if (!updateEmail) {
			let correctCharacteristics: any = [];
			for (let characteristic of rawUser.characteristics) {
				if (characteristic.type !== CharacteristicType.EMAIL) {
					correctCharacteristics.push(characteristic);
				}
			}
			rawUser.characteristics = correctCharacteristics;
		}

		return this.request(this.apiUrl + 'v1/IdentityGateway/Establishments/' + establishmentUid + '/Customers/' + user.uid + '/', rawUser, "PUT", false).then((JSONUser: any) => {
			return new PlatformUser(JSONUser);
		}).catch((data: any) => {
			if (data) {
				if (data.responseJSON) {
					if (data.responseJSON.error_details) {
						// On raccourcis l'objet pour prendre uniquement ce qui vient d'identity.
						data.responseJSON = JSON.parse(data.responseJSON.error_details);
						if (data.responseJSON.error_details === "Value is not valid JSON") {
							return Promise.reject("INVALID_JSON");
						} else if (data.responseJSON.error === "DUPLICATED_VALUE_NOT_ALLOWED" && data.responseJSON.error_details) {
							return Promise.reject({error_details: data.responseJSON.error, characteristic: new Characteristic(data.responseJSON.error_details)});
						}
					}
				}
			}

			return Promise.reject(data);
		});
	}

	public addAsCustomer(establishmentUid: string, profileUid: string): Promise<any> {
		return this.request(this.apiUrl + 'v1/Establishments/' + establishmentUid + '/Customers/', {profileUid: profileUid}, "POST", true).then((JSON: any) => {
			return JSON;
		}).catch(function (data: any) {
			return data;
		});
	}

	public createUserLegal(user: Company): Promise<Company> {
		return this.request(this.config.identityGateway_apiUrl + 'v1/Companies/', user.exportToJson(), "POST", false).then(function (JSONUser: any) {
			return new Company(JSONUser);
		}).catch(function (data: any) {
			return data;
		});
	}

	public getEmails(uid: Uuid): Promise<Array<ProfileEstablishment>> {
		return this.request(this.apiUrl + 'v1/Users/' + uid + '/Emails/', '', 'GET').then(function (emails: Array<any>) {
			return emails;
		});
	};

	public updateEmail(oldEmail: string, newEmail: string): Promise<any> {
		let data = {
			newEmail: newEmail,
			oldEmail: oldEmail
		};
		return this.request(this.apiUrl + 'v1/Users/ChangeEmail', data, 'PUT').then(function () {
		});
	};

	public setCredentialsValid(userUid: Uuid, userCredentials: any): Promise<any> {
		return this.request(this.apiUrl + 'v1/Users/' + userUid + '/Credentials/' + userCredentials.uid + '/SetValid', 'null', 'PUT').then(function () {
		});
	};

	public setKYC(userUid: Uuid, KYC: KYC, type: "IDENTITY_PROOF" | "ARTICLES_OF_ASSOCIATION" | "REGISTRATION_PROOF" | "SHAREHOLDER_DECLARATION" = "IDENTITY_PROOF") {
		return this.request(this.apiUrl + 'v1/Users/' + userUid + '/Kyc', KYC.exportOneToJson(type), 'POST').then(function () {
		});
	}

	public getKYC(userUid: Uuid) {
		return this.request(this.apiUrl + 'v1/Users/' + userUid + '/Kyc', null, 'GET').then(function (kycJSON: any) {
			return new KYC(kycJSON.kycResume);
		});
	}
}