import User from "gaia-sdk-js/lib/src/class/Identity/User";
import EstablishmentCustomerMetadata from "gaia-sdk-js/lib/src/class/Establishment/EstablishmentCustomerMetadata";

export default class PlatformUser extends User {
	public metadatas: EstablishmentCustomerMetadata[] = [];

	constructor(obj: any = {}) {
		super(obj);
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		PlatformUser.buildFromJson2(this, obj);
	}

	static buildFromJson2(instance: PlatformUser, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					if (prop === 'metadatas') {
						instance[prop] = [];
						for (let subProp of json[prop]) {
							instance[prop].push(new EstablishmentCustomerMetadata(subProp));
						}
					}
				}
			}
		}
	}

	exportToJson(): any {
		let JSONUser: any = super.exportToJson();
		JSONUser.metadatas = [];
		for (let metadata of this.metadatas) {
			JSONUser.metadatas.push(metadata.exportToJson());
		}
		return JSONUser;
	}

	exportToJsonEditable()  {
		let JSONUser: any = super.exportToJsonEditable();
		JSONUser.metadatas = [];
		for (let metadata of this.metadatas) {
			JSONUser.metadatas.push(metadata.exportToJson());
		}
		return JSONUser;
	}

	getMetadata(name: string) {
		for (let metadata of this.metadatas) {
			if (metadata.name === name) {
				return metadata.value;
			}
		}
		return null;
	}

	setMetadata(name: string, value: string) {
		let isAlreadyExist = false;
		for (let metadata of this.metadatas) {
			if (metadata.name === name) {
				isAlreadyExist = true;
				metadata.value = value;
			}
		}
		if (!isAlreadyExist) {
			this.metadatas.push(new EstablishmentCustomerMetadata({ name: name, value: value }));
		}
	}
}
