export default class Config {

    // Plateform /
    pl_name: string | null = null;
    pl_serviceUrl?: string;

    // Identity /
    identityGateway_apiUrl?: string;
    identityGateway_serviceUrl?: string;
    identityGateway_clientId?: string;

    // Invoice /
    invoiceGateway_apiUrl?: string;
    invoiceGateway_serviceUrl?: string;
    invoiceGateway_clientId?: string;

    // Gaia /
    gaiaGateway_apiUrl?: string;
    gaiaUser_serviceUrl?: string;

    // MDM /
    mdm_apiUrl?: string;
    mdm_serviceUrl?: string;

    sentryDsn?: string;
    sentryEnvironment?: string;

    version?: string;
    isDevelop(){
        return this.version==="develop"
    }


    constructor(obj: any = {}) {
        if (typeof obj === 'string') {
            obj = JSON.parse(obj);
        }
        Config.buildFromJson(this, obj);
    }

    private static buildFromJson(instance: any, json: any) {
        if (json) {
            for (let prop in json) {
                if (json.hasOwnProperty(prop)) {
                    instance[prop] = json[prop];
                }
            }
        }
    }

    getFromServer(url: string = '/config.json'): Promise<any> {
        let request: any = {
            url: url,
            method: 'GET',
            headers: {}
        };
        return new Promise(function (resolve, reject) {
            $.ajax(request).done(function (returnData: any) {
                resolve(returnData);
            }).fail(function (xhr: any, status: any, error: any) {
                reject();
            });
        });
    }

    isValid(): boolean {
        return false;
    }
}