import User from "./User";
import Address from "../Address";
import UserNatural from "./UserNatural";

export default class UserLegal extends User {
	public type!: number;
	public businessName!: string;

	/** Address **/
	public headquartersAddressUid!: Uuid | null;
	public headquarterAddress!: Address | null;

	/** UserLegalRepresentative **/
	public userLegalRepresentative!: User;

	/** UserLegalRepresentative **/
	public legalRepresentativeEmail!: string;
	public legalRepresentativeLastname!: string;
	public legalRepresentativeFirstname!: string;
	public legalRepresentativeAddressUid!: Uuid | null;
	public legalRepresentativeNationality!: string;
	public legalRepresentativeDateOfBirth!: string;
	public legalRepresentativeCountryOfResidence!: string;

	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		UserLegal.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			let userRep = new UserNatural();
			for (let prop in json) {
				if (prop === 'legalRepresentativeEmail') {
					instance.userLegalRepresentative= json[prop];
					userRep.email = json[prop];
				}
				else if (prop === 'legalRepresentativeLastname') {
					instance.legalRepresentativeLastname = json[prop];
					userRep.lastname = json[prop];
				}
				else if (prop === 'legalRepresentativeFirstname') {
					instance.legalRepresentativeFirstname= json[prop];
					userRep.firstname = json[prop];
				}
				else if (prop === 'legalRepresentativeAddressUid') {
					instance.legalRepresentativeAddressUid = json[prop];
					userRep.addressUid = json[prop];
				}
				else if (prop === 'legalRepresentativeNationality') {
					instance.legalRepresentativeNationality = json[prop];
					userRep.nationality = json[prop];
				}
				else if (prop === 'legalRepresentativeDateOfBirth') {
					instance.legalRepresentativeDateOfBirth = json[prop];
					userRep.dateOfBirth = json[prop];
				}
				else if (prop === 'legalRepresentativeCountryOfResidence') {
					instance.legalRepresentativeCountryOfResidence = json[prop];
					userRep.countryOfResidence = json[prop];
				}
				else {
					instance[prop] = json[prop];
				}
			}
			instance.userLegalRepresentative=userRep;
		}
	}

	exportToJson() {
		let JSONUserLegal: any = {};
		JSONUserLegal['type'] = this.type;
		JSONUserLegal['email'] = this.email;
		JSONUserLegal['userUid'] = this.userUid;
		JSONUserLegal['kycLevel'] = this.kycLevel;
		JSONUserLegal['businessName'] = this.businessName;
		JSONUserLegal['headquartersAddressUid'] = this.headquartersAddressUid;
		JSONUserLegal['legalRepresentativeEmail'] = this.legalRepresentativeEmail;
		JSONUserLegal['legalRepresentativeLastname'] = this.legalRepresentativeLastname;
		JSONUserLegal['legalRepresentativeFirstname'] = this.legalRepresentativeFirstname;
		JSONUserLegal['legalRepresentativeAddressUid'] = this.legalRepresentativeAddressUid;
		JSONUserLegal['legalRepresentativeNationality'] = this.legalRepresentativeNationality;
		JSONUserLegal['legalRepresentativeDateOfBirth'] = this.legalRepresentativeDateOfBirth;
		JSONUserLegal['legalRepresentativeCountryOfResidence'] = this.legalRepresentativeCountryOfResidence;
		return JSONUserLegal;
	}
}