import Model from "./Model";

export default class LegalAddress extends Model {

	public line1:string="";
	public line2!:string;
	public zipCode:string="";
	public city:string="";
	public country:string="";
	public state!:string;

	/**
	 * Construct a product from a javascript object of a json string
	 * @param obj either a javascript object or a json string
	 */
	constructor(obj: any={}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		LegalAddress.buildFromJson(this, obj);
	}

	/**
	 * builds the class instance from a javascript object
	 * @param instance this
	 * @param json javascript object
	 */
	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					if (prop === 'lastUpdateDate' || prop === 'creationDate') {
						instance[prop] = json[prop];
					} else {
						instance[prop] = json[prop];
					}
				}
			}
		}
	}

	//TODO: Do this function
	exportToJson() {
		let JSON:any={};
		JSON["line1"]=this.line1;
		JSON["line2"]=this.line2;
		JSON["zipCode"]=this.zipCode;
		JSON["city"]=this.city;
		JSON["country"]=this.country;
		JSON["state"]=this.state;
		return JSON;
	}

	getFormattedAddress(){
		let address:string="";
		if(this.line1&&this.line1!=='') {
			address += this.line1 + "\n";
		}
		if (this.line2 && this.line2 !== '') {
			address += this.line2 + "\n";
		}
		if (this.zipCode && this.state && this.country) {
			if (this.zipCode !== '') {
				address += this.zipCode;
			}
			if (this.state !== '') {
				address += " "+this.state;
			}
			if (this.country !== '') {
				address += " "+this.country;
			}
		}
		return address;
	}

	fill(Updater: LegalAddress) {
		this.line1 = Updater.line1;
		this.zipCode = Updater.zipCode;
		this.city = Updater.city;
		this.country = Updater.country;
		this.state = Updater.state;
	}
}