export default class StorageLib {
	static saveCache(name: string, obj: any): boolean {
		if (window.localStorage) {
			window.localStorage.setItem(name, JSON.stringify(obj));
			return true;
		}
		return false;
	};

	static loadFromCache(name: string): Array<any> {
		if (window.localStorage) {
			let objFromLocalStorage: any = window.localStorage.getItem(name);
			if (objFromLocalStorage) {
				objFromLocalStorage = JSON.parse(objFromLocalStorage);
			}
			if (objFromLocalStorage === null) {
				objFromLocalStorage = [];
			}
			return objFromLocalStorage;
		}

		return [];
	};

	static loadObject_from_cache(name: string): {[p:string]:number} {
		if (window.localStorage) {
			let objFromLocalStorage: any = window.localStorage.getItem(name);
			if (objFromLocalStorage) {
				objFromLocalStorage = JSON.parse(objFromLocalStorage);
			}
			if (objFromLocalStorage === null) {
				objFromLocalStorage = {};
			}
			return objFromLocalStorage;
		}

		return {};
	};
}