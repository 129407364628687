import Model from "./Model";
import Cart from "gaia-sdk-js/lib/src/class/Order/Cart";
import SubTask from "./SubTask";

export default class Task extends Model {

	public uid!: Uuid;
	public establishmentUid!: string;
	public creationDate!: string;
	public executeUserUid!: Uuid;
	public payload: Array<Cart> = [];
	public progressCount!: number;
	public progressTotal!: number;
	public status!: string;
	public subTasks: SubTask[] = [];
	public type!: "ORDER";

	/**
	 * Construct a product from a javascript object of a json string
	 * @param obj either a javascript object or a json string
	 */
	constructor(obj: any) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Task.buildFromJson(this, obj);
	}

	/**
	 * builds the class instance from a javascript object
	 * @param instance this
	 * @param json javascript object
	 */
	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					if (prop === 'subTasks' && json[prop]) {
						for (let subProp of json[prop]) {
							instance[prop].push(new SubTask(subProp));
						}
					} else {
						instance[prop] = json[prop];
					}
				}
			}
		}
	}

	//TODO: Do this function
	exportToJson() {
	}
}