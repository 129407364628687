import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import EstablishmentRepository from "../../modelRepositories/EstablishmentRepository";
import Session from "../../model/Session";
import User from "gaia-sdk-js/lib/src/class/Identity/User";
import {Company} from "gaia-sdk-js/lib/src/class/Identity/Company";
import {ResourceType} from "gaia-sdk-js/lib/src/class/Identity/Resource";
import UserRepository from "../../modelRepositories/UserRepository";
import {DependencyInjector, DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";

export class SignupManager {
	private injector: DependencyInjector = DependencyInjectorInstance();

	private establishmentRepository: EstablishmentRepository|any = this.injector.getInstance(EstablishmentRepository);
	private userRepository: UserRepository|any = this.injector.getInstance(UserRepository);

	constructor() {

	}

	isUserLoggedIn() {
		return AuthenticationManager.isConnected();
	}

	doesUserOwnAnyEstablishment() {
		return new Promise((resolve) => {
			this.establishmentRepository.getUserEstablishments(AuthenticationManager.getUserUid()).then((establishments) => {
				if (Session.getCurrentEstablishmentUid() === null && establishments[0]) {
					window.localStorage.setItem('partner_establishment', establishments[0].establishmentUid);
				}
				resolve(establishments.length > 0);
			});
		});
	}

	async isLegalRepresentativeConfigured() {
		let ownerResource!: Company | User;
		let legalRepresentativeResource!: User;
		let establishment = await this.establishmentRepository.get(Session.getCurrentEstablishmentUid());
		let resource = await this.userRepository.getProfileResource(Session.getCurrentEstablishmentUid(), establishment.profileRepresentativeUid ?? '');
		if (resource.resourceType === 'USER') {
			ownerResource = <User>resource;
			legalRepresentativeResource = ownerResource;
		} else {
			console.log(establishment.profileRepresentativeUid);

			ownerResource = <Company>resource;
			console.log(ownerResource.uid);

			let legalRepresentativeUid = ownerResource.legal_representative;
			if (legalRepresentativeUid) {
				legalRepresentativeResource = <User>await this.userRepository.getProfileResource(Session.getCurrentEstablishmentUid(), legalRepresentativeUid);
			} else {
				legalRepresentativeResource = new User();
				legalRepresentativeResource.resourceType = ResourceType.USER;
			}
		}

		let missing: string[] = [];

		if (ownerResource instanceof Company) {
			let businessNameCharacteristic = ownerResource.getCharacteristic('BUSINESS_NAME');
			if (!businessNameCharacteristic || businessNameCharacteristic.uid === null) {
				missing.push('nom de l\'entreprise');
			}

			let legalFormCharacteristic = ownerResource.getCharacteristic('LEGAL_FORM');
			if (!legalFormCharacteristic || legalFormCharacteristic.uid === null) {
				missing.push('forme légale');
			}
		}


		let lastNameCharacteristic = legalRepresentativeResource.getCharacteristic('LAST_NAME');
		if (!lastNameCharacteristic || lastNameCharacteristic.uid === null) {
			missing.push('nom de famille');
		}

		let firstNameCharacteristic = legalRepresentativeResource.getCharacteristic('FIRST_NAME');
		if (!firstNameCharacteristic || firstNameCharacteristic.uid === null) {
			missing.push('prénom');
		}

		let birthDateCharacteristic = legalRepresentativeResource.getCharacteristic('BIRTH_DATE');
		if (!birthDateCharacteristic || birthDateCharacteristic.uid === null) {
			missing.push('date de naissance');
		}

		let nationalityCharacteristic = legalRepresentativeResource.getCharacteristic('NATIONALITY');
		if (!nationalityCharacteristic || nationalityCharacteristic.uid === null) {
			missing.push('nationality');
		}

		if (missing.length === 0) {
			localStorage.setItem('identity_resource', JSON.stringify({
				companyUid: establishment.profileRepresentativeUid,
				resource: ownerResource.exportToJson(),
				legalRepresentative: legalRepresentativeResource.exportToJson(),
			}));
		}

		return missing.length === 0;
	}

	async isAccountFullyConfigured() {
		if (!this.isUserLoggedIn()) {
			return false;
		}

		let account = localStorage.getItem('identity_resource');
		if (account) {
			let accountParsed = JSON.parse(account);
			if (accountParsed.companyUid === AuthenticationManager.getUserUid()) {
				return true;
			}
		}

		let doesUserOwnAnyEstablishment = await this.doesUserOwnAnyEstablishment();
		if (!doesUserOwnAnyEstablishment) {
			return false;
		} else {
			let profile = await this.userRepository.getProfile(AuthenticationManager.getUserUid());
			if (profile.r1_data.resourceType === 'USER') {
				return true;
			}
		}

		let isLegalRepresentativeConfigured = await this.isLegalRepresentativeConfigured();
		if (!isLegalRepresentativeConfigured) {
			return false;
		}

		return true;
	}
}