import Model from "./Model";
import User from "gaia-sdk-js/lib/src/class/Identity/User";

export default class SubTask extends Model {

    public uid!: Uuid;
    public taskUid!: string;
    public payload!: object;
    public result!: string;

    /**
     * Construct a product from a javascript object of a json string
     * @param obj either a javascript object or a json string
     */
    constructor(obj: any) {
        super();
        if (typeof obj === 'string') {
            obj = JSON.parse(obj);
        }
        SubTask.buildFromJson(this, obj);
    }

    /**
     * builds the class instance from a javascript object
     * @param instance this
     * @param json javascript object
     */
    private static buildFromJson(instance: any, json: any) {
        if (json) {
            for (let prop in json) {
                if (json.hasOwnProperty(prop)) {
                    if (prop === 'result') {
                        instance[prop] = json[prop];
                    } else {
                        instance[prop] = json[prop];
                    }
                }
            }
        }
    }

    //TODO: Do this function
    exportToJson() {
    }

    getPayloadAsUser() {
        return new User(this.payload);
    }
}