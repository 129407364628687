import {VueMounted, VueParam, VueVar, VueVirtualComponent} from "horizon-js-front-sdk/lib/VueAnnotate";
import ProfileEstablishment from "gaia-sdk-js/lib/src/class/ProfileEstablishment";
import Session from "../../model/Session";
import Establishement from "gaia-sdk-js/lib/src/class/Establishment/Establishment";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import EstablishmentRepository from "../../modelRepositories/EstablishmentRepository";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";

export class EstablishmentSwitchComponent extends VueVirtualComponent {
	@VueParam() currentEstablishment!: Establishement;
	@VueParam() userEstablishments!: ProfileEstablishment[];

	@VueVar() creatingEstablishment: boolean = false;
	@VueVar() switching: string|null = null;
	@VueVar() creating: boolean = false;
	@VueVar() filter: string = '';

	@VueVar() establishmentName: string = '';

	@Autowire(EstablishmentRepository) establishmentRepository!: EstablishmentRepository;

	constructor() {
		super();
	}

	selectEstablishment(partner: ProfileEstablishment) {
		if(partner.establishmentUid !== this.currentEstablishment.uid) {
			this.switching = partner.establishmentUid;
			setTimeout(() => {
				Session.setCurrentEstablishment(partner.establishmentUid);
				Session.setCurrentPartnerPermissions(partner.permissions.exportToJson());
				location.reload();
			}, 1000);
		}
	}

	getFilteredEstablishments() {
		return [...this.userEstablishments]
			.sort((a, b) => a.establishmentName.localeCompare(b.establishmentName))
			.filter(e => e.establishmentName.toLowerCase().includes(this.filter.toLowerCase()));
	}

	createNewEstablishment() {
		this.creating = true;

		let establishment = new Establishement();
		establishment.name = this.establishmentName;
		establishment.profileRepresentativeUid = this.currentEstablishment.profileRepresentativeUid;

		this.establishmentRepository.create(establishment).then((establishment) => {
			this.establishmentRepository.getUserEstablishments(AuthenticationManager.getUserUid(), true).then((partners) => {
				this.creating = false;

				for(let partner of partners) {
					if(partner.establishmentUid === establishment.uid) {
						this.userEstablishments.unshift(partner);
						this.creatingEstablishment = false;

						this.selectEstablishment(partner);
					}
				}
			}).catch(() => { this.creating = false; });
		}).catch(() => { this.creating = false; });
	}

	static template = /** @lang HTML */`
		<div>
			<template v-if="!creatingEstablishment">
				<h3 class="ui header text-align-center" style="margin-bottom: 30px;"> 
					Changer d'établissement 
					<span class="sub header" v-if="currentEstablishment">
						Actuel : {{currentEstablishment.name}}
					</span>
				</h3>

				<div class="text-align-center" v-if="userEstablishments.length === 0">
					Vous n'avez aucun autre établissement
				</div>
		
				<div class="ui fluid icon input margin-bottom" v-if="userEstablishments.length > 5">
					<i class="search icon"></i>
					<input type="text" placeholder="Rechercher..." v-model="filter">
				</div>
		
				<template v-for="(establishment, index) in getFilteredEstablishments()">
					<div class="switch-establishment cursor-pointer" :class="{ current: currentEstablishment && establishment.establishmentUid === currentEstablishment.uid }" 
					@click="selectEstablishment(establishment)">
						<div>
							<i class="fa-duotone fa-circle-user fa-3x" style="opacity: 0.4"></i>
							<div class="informations">
								<span class="header"> {{ establishment.establishmentName }} </span>
								<span> {{ establishment.profile.fullname}} </span>
							</div>
						</div>
		
						<div class="switch-icon" :class="{ selected: switching === establishment.establishmentUid }">
							<i v-if="!(currentEstablishment && establishment.establishmentUid === currentEstablishment.uid)" 
							class="fa-regular fa-arrow-right-arrow-left" :class="switching === establishment.establishmentUid ? 'fa-flip' : ''" 
							style="opacity: 0.4; font-size: 20px; --fa-flip-x: 1; --fa-flip-y: 0;"></i>
							<i class="fa-regular fa-circle-check fa-3x" style="font-size: 20px;opacity: 0.6;" v-else></i>
						</div>
					</div>
					<hr v-if="(index + 1) < userEstablishments.length" style="opacity: 0.4;"/>
				</template>
		
				<div style="margin-top: 40px; text-align: center;">
					<button class="ui tiny primary button" @click="creatingEstablishment = true">
						<i class="ui plus icon"></i>
						Nouvel établissement
					</button>
				</div>
			</template>
			
			<template v-else>
				<h3 class="text-align-center" style="margin-bottom: 30px;"> Nouvel établissement</h3>
				
				<div class="ui fluid input">
					<input v-model="establishmentName" type="text" placeholder="Nom de l'établissement" />
				</div>
				
				<div class="text-align-center margin-top">
					<button class="ui primary button" :class="{ loading: creating, disabled: creating }" @click="createNewEstablishment()"> Créer l'établissement </button>
				</div>
			</template>
		</div>
	`;
}