import Model from "../Model";

export default class KYC extends Model {
	public level!: string;

	/**
	 * 0=> Natural
	 * 1=> Business
	 * 2=> Organization
	 * 3=> Soletrader
	 * **/
	type!: number;

	/** Required Infos **/
	public headquartersAddress!: string;
	public legalRepresentativeEmail!: string;
	public legalRepresentativeAddress!: string;


	/** Required KYC Document **/
	public IDENTITY_PROOF!: string;
	public ARTICLES_OF_ASSOCIATION!: string;
	public REGISTRATION_PROOF!: string;
	public SHAREHOLDER_DECLARATION!: string;

	/** Object **/
	public articlesOfAssociation: {
		refusedReasonsMessage: string | null,
		refusedType: string | null,
		status: KycDocumentStatus.CREATED | KycDocumentStatus.NOT_SENT | KycDocumentStatus.REFUSED | KycDocumentStatus.VALIDATED | KycDocumentStatus.VALIDATION_ASKED | 'CREATED' | null
	} = {refusedReasonsMessage: null, refusedType: null, status: null};
	public identityProof: {
		refusedReasonsMessage: string | null,
		refusedType: string | null,
		status: KycDocumentStatus.CREATED | KycDocumentStatus.NOT_SENT | KycDocumentStatus.REFUSED | KycDocumentStatus.VALIDATED | KycDocumentStatus.VALIDATION_ASKED | 'CREATED' | null
	} = {refusedReasonsMessage: null, refusedType: null, status: null};
	public registrationProof: {
		refusedReasonsMessage: string | null,
		refusedType: string | null,
		status: KycDocumentStatus.CREATED | KycDocumentStatus.NOT_SENT | KycDocumentStatus.REFUSED | KycDocumentStatus.VALIDATED | KycDocumentStatus.VALIDATION_ASKED | 'CREATED' | null
	} = {refusedReasonsMessage: null, refusedType: null, status: null};
	public shareholderDeclaration: {
		refusedReasonsMessage: string | null,
		refusedType: string | null,
		status: KycDocumentStatus.CREATED | KycDocumentStatus.NOT_SENT | KycDocumentStatus.REFUSED | KycDocumentStatus.VALIDATED | KycDocumentStatus.VALIDATION_ASKED | 'CREATED' | null
	} = {refusedReasonsMessage: null, refusedType: null, status: null};

	/** Required KYC Document UUID**/
	public ARTICLES_OF_ASSOCIATION_UID!: Uuid;
	public REGISTRATION_PROOF_UID!: Uuid;
	public SHAREHOLDER_DECLARATION_UID!: Uuid;

	/** Futur **/
	public ubo!: Uuid;
	public uboUsers!: Array<any>;

	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		KYC.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (prop === 'articlesOfAssociation'
					|| prop === 'identityProof'
					|| prop === 'registrationProof'
					|| prop === 'shareholderDeclaration') {
					if (json[prop] !== null) {
						instance[prop] = json[prop];
					}
				} else {
					instance[prop] = json[prop];
				}
			}
		}
	}

	exportToJson(): any {
		let documents: Array<any> = [];
		if (this.type === 1) {
			documents.push({type: "IDENTITY_PROOF", documents: [this.IDENTITY_PROOF]});
			documents.push({type: "ARTICLES_OF_ASSOCIATION", documents: [this.ARTICLES_OF_ASSOCIATION]});
			documents.push({type: "REGISTRATION_PROOF", documents: [this.REGISTRATION_PROOF]});
			documents.push({type: "SHAREHOLDER_DECLARATION", documents: [this.SHAREHOLDER_DECLARATION]});
		} else if (this.type === 2) {
			documents.push({type: "IDENTITY_PROOF", documents: [this.IDENTITY_PROOF]});
			documents.push({type: "ARTICLES_OF_ASSOCIATION", documents: [this.ARTICLES_OF_ASSOCIATION]});
			documents.push({type: "REGISTRATION_PROOF", documents: [this.REGISTRATION_PROOF]});
		} else if (this.type === 3) {
			documents.push({type: "IDENTITY_PROOF", documents: [this.IDENTITY_PROOF]});
			documents.push({type: "REGISTRATION_PROOF", documents: [this.REGISTRATION_PROOF]});
		} else if (this.type === 0) {
			documents.push({type: "IDENTITY_PROOF", documents: [this.IDENTITY_PROOF]});
		}
		return documents;
	}

	exportOneToJson(type: "IDENTITY_PROOF" | "ARTICLES_OF_ASSOCIATION" | "REGISTRATION_PROOF" | "SHAREHOLDER_DECLARATION" = "IDENTITY_PROOF"): any {
		if (type === KycDocuments.IDENTITY_PROOF) {
			return {type: KycDocuments.IDENTITY_PROOF, documents: [this.IDENTITY_PROOF]};
		} else if (type === KycDocuments.ARTICLES_OF_ASSOCIATION) {
			return {type: KycDocuments.ARTICLES_OF_ASSOCIATION, documents: [this.ARTICLES_OF_ASSOCIATION]};
		} else if (type === KycDocuments.REGISTRATION_PROOF) {
			return {type: KycDocuments.REGISTRATION_PROOF, documents: [this.REGISTRATION_PROOF]};
		} else if (type === KycDocuments.SHAREHOLDER_DECLARATION) {
			return {type: KycDocuments.SHAREHOLDER_DECLARATION, documents: [this.SHAREHOLDER_DECLARATION]};
		}
	}

	isValid(type: KycDocuments.IDENTITY_PROOF | KycDocuments.ARTICLES_OF_ASSOCIATION | KycDocuments.REGISTRATION_PROOF | KycDocuments.SHAREHOLDER_DECLARATION) {
		if (type === KycDocuments.IDENTITY_PROOF) {
			return this.registrationProof.status === KycDocumentStatus.VALIDATED;
		} else if (type === KycDocuments.ARTICLES_OF_ASSOCIATION) {
			return this.articlesOfAssociation.status === KycDocumentStatus.VALIDATED;
		} else if (type === KycDocuments.REGISTRATION_PROOF) {
			return this.registrationProof.status === KycDocumentStatus.VALIDATED;
		} else if (type === KycDocuments.SHAREHOLDER_DECLARATION) {
			return this.shareholderDeclaration.status === KycDocumentStatus.VALIDATED;
		}
	}

	isValidSoon(type: KycDocuments.IDENTITY_PROOF | KycDocuments.ARTICLES_OF_ASSOCIATION | KycDocuments.REGISTRATION_PROOF | KycDocuments.SHAREHOLDER_DECLARATION) {
		if (type === KycDocuments.IDENTITY_PROOF) {
			return this.identityProof.status === KycDocumentStatus.VALIDATION_ASKED || this.articlesOfAssociation.status === KycDocumentStatus.CREATED;
		} else if (type === KycDocuments.ARTICLES_OF_ASSOCIATION) {
			return this.articlesOfAssociation.status === KycDocumentStatus.VALIDATION_ASKED || this.articlesOfAssociation.status === KycDocumentStatus.CREATED;
		} else if (type === KycDocuments.REGISTRATION_PROOF) {
			return this.registrationProof.status === KycDocumentStatus.VALIDATION_ASKED || this.articlesOfAssociation.status === KycDocumentStatus.CREATED;
		} else if (type === KycDocuments.SHAREHOLDER_DECLARATION) {
			return this.shareholderDeclaration.status === KycDocumentStatus.VALIDATION_ASKED || this.articlesOfAssociation.status === KycDocumentStatus.CREATED;
		}
	}

	isRefused(type: KycDocuments.IDENTITY_PROOF | KycDocuments.ARTICLES_OF_ASSOCIATION | KycDocuments.REGISTRATION_PROOF | KycDocuments.SHAREHOLDER_DECLARATION) {
		if (type === KycDocuments.IDENTITY_PROOF) {
			return this.identityProof.status === KycDocumentStatus.REFUSED;
		} else if (type === KycDocuments.ARTICLES_OF_ASSOCIATION) {
			return this.articlesOfAssociation.status === KycDocumentStatus.REFUSED;
		} else if (type === KycDocuments.REGISTRATION_PROOF) {
			return this.registrationProof.status === KycDocumentStatus.REFUSED;
		} else if (type === KycDocuments.SHAREHOLDER_DECLARATION) {
			return this.shareholderDeclaration.status === KycDocumentStatus.REFUSED;
		}
	}

	isNotSend(type: KycDocuments.IDENTITY_PROOF | KycDocuments.ARTICLES_OF_ASSOCIATION | KycDocuments.REGISTRATION_PROOF | KycDocuments.SHAREHOLDER_DECLARATION) {
		if (type === KycDocuments.IDENTITY_PROOF) {
			return this.identityProof.status === KycDocumentStatus.NOT_SENT
				|| this.identityProof.status === null
				;
		} else if (type === KycDocuments.ARTICLES_OF_ASSOCIATION) {
			return this.articlesOfAssociation.status === KycDocumentStatus.NOT_SENT
				|| this.articlesOfAssociation.status === null
				;

		} else if (type === KycDocuments.REGISTRATION_PROOF) {
			return this.registrationProof.status === KycDocumentStatus.NOT_SENT
				|| this.registrationProof.status === null
				;

		} else if (type === KycDocuments.SHAREHOLDER_DECLARATION) {
			return this.shareholderDeclaration.status === KycDocumentStatus.NOT_SENT
				|| this.shareholderDeclaration.status === null
				;
		}
	}
}

export enum KycDocumentStatus {
	NOT_SENT = 'NOT_SENT',
	CREATED = 'CREATED',
	VALIDATION_ASKED = 'VALIDATION_ASKED',
	REFUSED = 'REFUSED',
	VALIDATED = 'VALIDATED'
}

enum KycDocuments {
	IDENTITY_PROOF = 'IDENTITY_PROOF',
	ARTICLES_OF_ASSOCIATION = 'ARTICLES_OF_ASSOCIATION',
	REGISTRATION_PROOF = 'REGISTRATION_PROOF',
	SHAREHOLDER_DECLARATION = 'SHAREHOLDER_DECLARATION',
}