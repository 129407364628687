import Model from "../Model";

export default class User extends Model {
	public uid: Uuid | null = null;

	public email!: string;
	private _password!: string;

	public kycLevel!: string | number;
	public userUid!: Uuid;

	public type!: number;

	public nbOrders!: string;
	public creationDate!: Date;

	constructor(obj: any = {}) {
		super();
	}

	exportToJson() {
	}

	isVerify():boolean {
		return (this.kycLevel !== "LIGHT" && this.kycLevel !== 0);
	}

	/** SETTER & GETTER **/
	get password(): string {
		if (this._password === undefined || this._password === null)
			this._password = '';
		return this._password;
	}

	set password(value: string) {
		this._password = value;
	}
}