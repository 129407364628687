if (typeof String.prototype.splice === 'undefined')
	String.prototype.splice = function (start: number, delCount: number, newSubStr: string) {
		return this.slice(0, start) + newSubStr + this.slice(start + Math.abs(delCount));
	};

if (typeof String.prototype.accentFold === 'undefined') {
	String.prototype.accentFold = function () {
		let accentMap:any = {
			'á': 'a',
			'à': 'a',
			'â': 'a',
			'ä': 'a',
			'ã': 'a',

			'ç': 'c',

			'é': 'e',
			'è': 'e',
			'ê': 'e',
			'ë': 'e',

			'í': 'i',
			'î': 'i',
			'ï': 'i',

			'ó': 'o',
			'ô': 'o',
			'ö': 'o',

			'ú': 'u',
			'ü': 'u',
			'û': 'u',

			'ÿ': 'y'
		};

		if (!this) {
			return '';
		}

		let ret = '';
		for (let i = 0; i < this.length; i++) {
			ret += accentMap[this.charAt(i)] || this.charAt(i);
		}
		return ret;
	};
}