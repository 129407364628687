import Model from "./Model";

export default class Company extends Model {
	public name: string='';
	public legalForm!: string;
	public siren!: string;
	public siret!: string;
	public rcs!: string;
	public vatNumber!: string;
	public shareCapital!: string;


	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Company.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					instance[prop] = json[prop];
				}
			}
		}
	}

	public exportToJson(withGroups: boolean = true): any {
		let JSONCompany: any = {};
		if (this.name) JSONCompany.name = this.name;
		if (this.legalForm) JSONCompany.legalForm = this.legalForm;
		if (this.siren) JSONCompany.siren = this.siren;
		if (this.siret) JSONCompany.siret = this.siret;
		if (this.rcs) JSONCompany.rcs = this.rcs;
		if (this.vatNumber) JSONCompany.vatNumber = this.vatNumber;
		if (this.shareCapital) JSONCompany.shareCapital = this.shareCapital;
		return JSONCompany;
	}
}