import Repository from "./Repository";
import Session from "../model/Session";
import StorageLib from "../libs/StorageLib";
import {Error_Api} from "../libs/Error_Api";
import Establishement from "gaia-sdk-js/lib/src/class/Establishment/Establishment";
import ProfileEstablishment from "gaia-sdk-js/lib/src/class/ProfileEstablishment";
import LegalInformation from "../model/LegalInformation";
import KYC, {KycDocumentStatus} from "../model/User/KYC";
import EstablishmentOrderSuppData from "gaia-sdk-js/lib/src/class/Order/OrderData/EstablishmentOrderSuppData";

export default class EstablishmentRepository extends Repository {
	private static DELAY_OF_CACHE: number = 60 * 1000; // X * 1000 => X in seconde.

	/** Establishment **/
	private establishment_cache: { timestamps: number, establishment: Establishement } | null = null;
	private promise_get: Promise<Establishement> | null = null;

	/** Profile Establishment **/
	private profile_establishment_cache: { [userUid: string]: { timestamps: number, profileEstablishment: ProfileEstablishment[] } } = {};
	private profile_establishment_promise: { [userUid: string]: Promise<ProfileEstablishment[]> } = {};

	/** EstablishmentOrderSuppData **/
	/** Profile Establishment **/
	private order_supp_data_cache: { [establishmentUid: string]: { timestamps: number, orderSuppData: EstablishmentOrderSuppData[] } } = {};
	private order_supp_data_promise: { [establishmentUid: string]: Promise<EstablishmentOrderSuppData[]> } = {};

	constructor() {
		super();
	}

	public getCurrent(): Promise<Establishement | null> {
		if (Session.getCurrentEstablishmentUid()) {
			return this.get(Session.getCurrentEstablishmentUid()).then(function (establishement: Establishement) {
				StorageLib.saveCache('gaia_current_establishement', establishement);
				return establishement;
			});
		} else {
			return Promise.reject(Error_Api.NO_ESTABLISHMENT).catch(() => {
				return null;
			});
		}
	};

	public get(establishmentUuid: Uuid): Promise<Establishement> {
		if (this.promise_get !== null) {
			return this.promise_get;
		}

		// Cache valide
		if (this.establishment_cache !== null && this.establishment_cache.timestamps > new Date().getTime() - EstablishmentRepository.DELAY_OF_CACHE) {
			return Promise.resolve(this.establishment_cache.establishment);
		}

		return this.promise_get = this.request(this.apiUrl + 'v1/Establishments/' + establishmentUuid + '/', '', 'GET').then(
			(JSONEstablishment: any) => {
				let establishment: Establishement = new Establishement(JSONEstablishment);
				this.establishment_cache = {
					timestamps: new Date().getTime(),
					establishment: establishment
				};
				this.promise_get = null;

				return establishment;
			}
		);
	};

	public update(establishment: Establishement): Promise<any> {
		return this.request(this.apiUrl + 'v1/Establishments/' + establishment.uid + '/', establishment.exportToJson(), 'PUT').then((JSON: any) => {
			//On clean le cache
			this.establishment_cache = null;
		});
	};

	public getUserEstablishments(userUid: Uuid, forceRefreshCache: boolean = false): Promise<Array<ProfileEstablishment>> {
		// Check si la même promesse est déjà entrain d'être demandée au serveur.
		if (this.profile_establishment_promise[userUid] !== undefined) {
			return this.profile_establishment_promise[userUid];
		}

		if (!forceRefreshCache) {
			// Check si un cache est disponible.
			let cache = this.profile_establishment_cache[userUid];
			if (cache !== undefined && cache !== null && cache.timestamps > (new Date().getTime() - EstablishmentRepository.DELAY_OF_CACHE)) {
				return Promise.resolve(cache.profileEstablishment);
			}
		}

		return this.profile_establishment_promise[userUid] = this.request(this.apiUrl + 'v1/Users/' + userUid + '/Partners/', '', 'GET').then((rawUserPartners: Array<string>) => {
			if (rawUserPartners !== null) {
				let userPartners: Array<ProfileEstablishment> = [];
				for (let userEstablishment of rawUserPartners) {
					userPartners.push(new ProfileEstablishment(userEstablishment));
				}

				// On crée le cache
				this.profile_establishment_cache[userUid] = {
					timestamps: new Date().getTime(),
					profileEstablishment: userPartners
				};

				// On supprimer la promesse de son cache.
				delete this.profile_establishment_promise[userUid];
				return userPartners;
			} else {
				return Promise.reject(new Error('getUserEstablishments() Fail')).then(function (error) {
					// not called
				}, function (error) {
					console.log(error);
				});
			}
		});
	};

	public create(newEstablishement: Establishement): Promise<Establishement> {
		let data = {
			uid: null,
			name: newEstablishement.name,
			profileRepresentativeUid: newEstablishement.profileRepresentativeUid,
			options: newEstablishement.options ? newEstablishement.options.exportToJson() : null
		};
		return this.request(this.apiUrl + 'v1/Establishments/', data, 'POST').then((JSONCreateEstablishment: any) => {
			//On clean le cache
			this.establishment_cache = null;
			return new Establishement(JSONCreateEstablishment);
		});

	};

	public getCompanyWithSirenFromKbisSociete(siren: string): Promise<LegalInformation> {
		return this.request("https://api.kbis-societe.fr/api/v1/France/Companies/" + siren + "/", null, "GET", false).then(function (JSONLegalInformation: any) {
			let legalInfo: LegalInformation = new LegalInformation();
			if (JSONLegalInformation.denomination !== null) {
				legalInfo.companyName = JSONLegalInformation.denomination;
			} else if (JSONLegalInformation.denomination_usuelle_1 !== null) {
				legalInfo.companyName = JSONLegalInformation.denomination_usuelle_1;
			} else if (JSONLegalInformation.denomination_usuelle_2 !== null) {
				legalInfo.companyName = JSONLegalInformation.denomination_usuelle_1;
			} else if (JSONLegalInformation.denomination_usuelle_3 !== null) {
				legalInfo.companyName = JSONLegalInformation.denomination_usuelle_1;
			} else if (JSONLegalInformation.prenom_1 !== null && JSONLegalInformation.nom !== null) {
				legalInfo.companyName = JSONLegalInformation.prenom_1 + " " + JSONLegalInformation.nom;
			} else if (JSONLegalInformation.prenom_1 !== null) {
				legalInfo.companyName = JSONLegalInformation.prenom_1;
			} else if (JSONLegalInformation.nom) {
				legalInfo.companyName = JSONLegalInformation.nom;
			}

			legalInfo.siren = JSONLegalInformation.siren;
			legalInfo.NIC = JSONLegalInformation.nic_siege;
			legalInfo.vatNumber = JSONLegalInformation.vat;
			legalInfo.legalFormLongVersion = JSONLegalInformation.categorie_juridique_libelle;

			//Address : adresse du siège social
			if (legalInfo.address) {
				legalInfo.address.line1 = JSONLegalInformation.etablissement_siege.geo_l4;
				legalInfo.address.zipCode = JSONLegalInformation.etablissement_siege.code_postal;
				legalInfo.address.city = JSONLegalInformation.etablissement_siege.libelle_commune;
				legalInfo.address.country = "FRANCE";
			}

			//RCS : on prend le premier pour le moment
			if (JSONLegalInformation.greffes) {
				for (let greffe of JSONLegalInformation.greffes) {
					legalInfo.rcs = greffe.name;
					break;
				}
			}

			//Contact
			if (JSONLegalInformation.phone_number)
				legalInfo.phoneNumber = JSONLegalInformation.phone_number;
			if (JSONLegalInformation.email)
				legalInfo.email = JSONLegalInformation.email;

			return legalInfo;
		});
	}

	public getCompanyFromKbisSocieteUrl(): string {
		return 'https://api.kbis-societe.fr/api/v1/France/Companies/Search/{search}'
	}

	/** Config **/

	public getConfigSwitchly(establishmentUid: Uuid, raw: boolean = false) {
		return this.request(this.apiUrl + 'v1/Establishments/' + establishmentUid + '	/Configs/Switchly/', 'GET')
			.then((JSON: any) => {
				if (raw) {
					return JSON;
				}
				let kyc = new KYC();
				if (JSON.errors[0]) {
					for (let kycDocument of JSON.pendingKycDocuments) {
						if (kycDocument.type === "IDENTITY_PROOF") {
							kyc.IDENTITY_PROOF = kycDocument.data;
							kyc.identityProof.status = KycDocumentStatus.VALIDATION_ASKED;

						} else if (kycDocument.type === "ARTICLES_OF_ASSOCIATION") {
							kyc.ARTICLES_OF_ASSOCIATION = kycDocument.data;
							kyc.articlesOfAssociation.status = KycDocumentStatus.VALIDATION_ASKED;

						} else if (kycDocument.type === "REGISTRATION_PROOF") {
							kyc.REGISTRATION_PROOF = kycDocument.data;
							kyc.registrationProof.status = KycDocumentStatus.VALIDATION_ASKED;

						} else if (kycDocument.type === "SHAREHOLDER_DECLARATION") {
							kyc.SHAREHOLDER_DECLARATION = kycDocument.data;
							kyc.shareholderDeclaration.status = KycDocumentStatus.VALIDATION_ASKED;

						}
					}
				} else {
					kyc.identityProof.status = KycDocumentStatus.VALIDATED;
					kyc.articlesOfAssociation.status = KycDocumentStatus.VALIDATED;
					kyc.registrationProof.status = KycDocumentStatus.VALIDATED;
					kyc.shareholderDeclaration.status = KycDocumentStatus.VALIDATED;
				}
				return kyc;
			}).catch((xhr: any) => {
				if (xhr.status === 500) {
					console.warn("Switchly n'est pas accessible.")
				}
				return Promise.reject();
			});
	}

	public addConfigSwitchlyDocument(establishmentUid: Uuid, data: any) {
		return this.request(this.apiUrl + 'v1/Establishments/' + establishmentUid + '/Configs/Switchly/KycDocument', data, 'POST').then((JSON: any) => {
			// console.log(777, JSON);
		});
	}

	public getAllEstablishmentsAsAdmin() {
		return this.request(this.apiUrl + 'v1/Establishments/Admin', null, 'GET').then((JSONEstablishments: any) => {
			let establishments: Establishement[] = [];
			for (let JSONEstablishment of JSONEstablishments) {
				establishments.push(new Establishement(JSONEstablishment));
			}
			return establishments;
		});
	}

	public getOrderSuppData(establishmentUid: Uuid): Promise<EstablishmentOrderSuppData[]> {

		// Check si la même promesse est déjà entrain d'être demandée au serveur.
		if (this.order_supp_data_promise[establishmentUid] !== undefined) {
			return this.order_supp_data_promise[establishmentUid];
		}

		// Check si un cache est disponible.
		let cache = this.order_supp_data_cache[establishmentUid];
		if (cache !== undefined && cache !== null && !EstablishmentRepository.is_cache_expired(cache.timestamps, EstablishmentRepository.DELAY_OF_CACHE)) {
			return Promise.resolve(cache.orderSuppData);
		}

		let promise = this.request(this.apiUrl + 'v1/Establishments/' + establishmentUid + '/EstablishmentOrderSuppDatas/', null, 'GET');
		this.order_supp_data_promise[establishmentUid] = promise;
		promise.then((JSONEstablishmentOrderSuppDatas: any) => {
			let establishmentOrderSuppData: EstablishmentOrderSuppData[] = [];
			for (let JSONEstablishmentOrderSuppData of JSONEstablishmentOrderSuppDatas) {
				establishmentOrderSuppData.push(new EstablishmentOrderSuppData(JSONEstablishmentOrderSuppData));
			}

			// On crée le cache
			this.order_supp_data_cache[establishmentUid] = {
				timestamps: new Date().getTime(),
				orderSuppData: establishmentOrderSuppData
			};

			// On supprimer la promesse de son cache.
			delete this.order_supp_data_promise[establishmentUid];

			return establishmentOrderSuppData;
		});
		return promise;
	}

	static is_cache_expired(timestamps: number, cacheDelay: number) {
		return timestamps <= (new Date().getTime() - cacheDelay);
	}
}