import Model from "./Model";
import LegalAddress from "./LegalAddress";

export default class LegalInformation extends Model {
	set legalFormLongVersion(value: string) {
		this.legalForm = "";
		if (value.startsWith("EIRL")) this.legalForm = "EIRL";
		else if (value.startsWith("EI")) this.legalForm = "EI";
		else if (value.startsWith("Entrepreneur individuel")) this.legalForm = "EI";
		else if (value.startsWith("EURL")) this.legalForm = "EURL";
		else if (value.startsWith("SARL")) this.legalForm = "SARL";
		else if (value.startsWith("SASU")) this.legalForm = "SASU";
		else if (value.startsWith("Société par actions simplifiée à associé unique")) this.legalForm = "SASU";
		else if (value.startsWith("SAS")) this.legalForm = "SAS";
		else if (value.startsWith("SNC")) this.legalForm = "SNC";
		else if (value.startsWith("SCOP")) this.legalForm = "SCOP";
		else if (value.startsWith("SA")) this.legalForm = "SA";
		else if (value.startsWith("Association déclarée")) this.legalForm = "Association déclarée";
		else if (value.startsWith("Association reconnue d'utilisaté publique")) this.legalForm = "RUP";
		else if (value.startsWith("Fondation")) this.legalForm = "Fondation";

		else this.legalForm = "SARL";
	}

	public uid!: Uuid;

	//(Numéro Interne de classement)
	private _NIC: string = "";
	get NIC(): string {
		return this._NIC;
	}

	set NIC(value: string) {
		if (value.length > 5) {
			value = value.slice(0, 5);
		}
		this._NIC = value;
	}


	private _siren: string = "";
	get siren(): string {
		return this._siren;
	}

	set siren(value: string) {
		if (value && value.length > 9) {
			value = value.slice(0, 9);
		}
		this._siren = value;
	}

	get siret(): string {
		return this._siren + this._NIC;
	}

	set siret(value: string) {
		let i: number = 0;
		if (value && value.length > 9) {
			this._NIC = value.substr(9, value.length - 1);
		}
	}

	get vatNumber(): string {
		return this._vatNumber;
	}

	set vatNumber(value: string) {
		try {
			this._vatNumber = value.split(" ").join("")
		} catch (e) {
			this._vatNumber = value;
		}
	}

	private _vatNumber: string = "";
	public rcs!: string;
	public legalForm: string = "SARL";

	public phoneNumber!: string;
	public email!: string;
	public lastname!: string;
	public firstname!: string;
	public companyName!: string;
	public beforeText!: string;
	public afterText!: string;
	public showAddressOpposition: boolean = false;

	private _isBillingAdresseSameAsAddress: boolean = true;
	get isBillingAdresseSameAsAddress(): boolean {
		return this._isBillingAdresseSameAsAddress;
	}

	set isBillingAdresseSameAsAddress(value: boolean) {
		this._isBillingAdresseSameAsAddress = value;
	}

	public address: LegalAddress | null = new LegalAddress();
	public billingAddress: LegalAddress = new LegalAddress();

	/**
	 * Construct a product from a javascript object of a json string
	 * @param obj either a javascript object or a json string
	 */
	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		LegalInformation.buildFromJson(this, obj);
	}

	/**
	 * builds the class instance from a javascript object
	 * @param instance this
	 * @param json javascript object
	 */
	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					if (prop === 'address' || prop === 'billingAddress') {
						instance[prop] = new LegalAddress(json[prop]);
					} else {
						instance[prop] = json[prop];
					}
				}
			}
		}
	}


	emptyValues() {
		LegalInformation.buildFromJson(this, {});
	}

	//TODO: Do this function
	exportToJson() {
		let JSON: any = {};
		JSON.uid = this.uid;
		JSON.siret = this.siret;
		JSON.siren = this._siren;
		JSON.vatNumber = this._vatNumber;
		JSON.rcs = this.rcs;
		JSON.legalForm = this.legalForm;
		JSON.phoneNumber = this.phoneNumber;
		JSON.email = this.email;
		JSON.lastname = this.lastname;
		JSON.firstname = this.firstname;
		JSON.companyName = this.companyName;
		JSON.beforeText = this.beforeText;
		JSON.afterText = this.afterText;

		if (this.address) {
			JSON.address = this.address.exportToJson();
			if (this._isBillingAdresseSameAsAddress) {
				JSON.billingAddress = this.address.exportToJson();
			} else {
				JSON.billingAddress = this.billingAddress.exportToJson();
			}
		} else {
			JSON.address = null;
			JSON.billingAddress = null;
			JSON.showAddressOpposition = true;
		}
		return JSON;
	}

	fill(Updater: LegalInformation) {
		this.companyName = Updater.companyName;
		this._siren = Updater._siren;
		this._NIC = Updater._NIC;
		this._vatNumber = Updater._vatNumber;
		this.rcs = Updater.rcs;
		this.legalForm = Updater.legalForm;
		this.phoneNumber = Updater.phoneNumber;

		if (this.address && Updater.address) {
			this.address.fill(Updater.address);
		}
		this.billingAddress.fill(Updater.billingAddress);
	}
}