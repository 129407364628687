import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import {DependencyInjector, DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import Config from "../../Config";

export class AuthManager {
    public static disconnect() {
        let injector: DependencyInjector = DependencyInjectorInstance();
        let config:any = injector.getInstance(Config);

        window.localStorage.removeItem('partner_establishment');
        window.localStorage.removeItem('user_partner_identities');
        window.localStorage.removeItem('gaia_current_establishement');
        window.localStorage.removeItem('identity_resource');
        if (config.identityGateway_apiUrl) {
            AuthenticationManager.disconnect(config.identityGateway_apiUrl, config.pl_serviceUrl);
        }
    }
}