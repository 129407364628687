import EstablishmentRepository from "../../modelRepositories/EstablishmentRepository";
import MenuRepository from "../../modelRepositories/Modules/MenuRepository";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import {VueClass, VueRequireComponent, VueVar} from "horizon-js-front-sdk/lib/VueAnnotate";
import sidebar from './sidebar.html';
import {Router} from "horizon-js-front-sdk/lib/Router";
import Config from "../../Config";
import Establishement from "gaia-sdk-js/lib/src/class/Establishment/Establishment";
import Session from "../../model/Session";
import ProfileEstablishment from "gaia-sdk-js/lib/src/class/ProfileEstablishment";
import AppStateListener from "../../class/Util/AppStateListener";
import {BottomDrawerComponent} from "../../controller/Components/Drawer/bottom-drawer";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import {Native} from "@groupk/native-bridge";
import {EstablishmentSwitchComponent} from "../../controller/Components/EstablishmentSwitchComponent";
import Vue from "vue";
import {AppInfo} from "@groupk/native-bridge/dist/bridges/SystemInfoNative";

$('#sidebar').html(sidebar);

@VueClass()
@VueRequireComponent('bottom-drawer', BottomDrawerComponent)
@VueRequireComponent('establishment-switch', EstablishmentSwitchComponent)
export class SidebarVue extends Vue {
	@VueVar(false) displayMobileMenu!: boolean;
	@VueVar() navigations: {
		top: {name: string, pageTitle: string, url: string, icon: string}[],
		events: {name: string, pageTitle: string, url: string, icon: string}[],
		bar: {name: string, pageTitle: string, url: string, icon: string}[],
		bottom: {name: string, pageTitle: string, url: string, icon: string}[]
	} = { top: [], events: [], bar: [], bottom: [] };

	@VueVar() currentEstablishment!: Establishement;
	@VueVar() currentPartner!: ProfileEstablishment;
	@VueVar() nativeVersion: string|null = null;
	@VueVar() currentPageTitle: string = '';
	@VueVar() opened: boolean = false;
	@VueVar() minimized: boolean = false;
	@VueVar() hidden: boolean = false;

	@VueVar() userEstablishments: ProfileEstablishment[] = [];
	@VueVar() switchEstablishment: boolean = false;

	@VueVar() display: boolean = true;

	@Autowire(EstablishmentRepository) establishmentRepository!: EstablishmentRepository;
	@Autowire(Config) config!: Config;
	@Autowire(Router) router!: Router;
	@Autowire(Native.SystemInfo) systemInfo !: Native.SystemInfo;
	@Autowire(AppStateListener) appStateListener!: AppStateListener;

	constructor(container: string, vueConstructor: any = null) {
		super(vueConstructor);

		this.loadSidebar();

		this.currentPageTitle = this.appStateListener.pageTitle.raw;
		document.title = this.config.pl_name + ' - ' + this.appStateListener.pageTitle.formatted;
		this.opened = this.appStateListener.openedSidebar;
		this.minimized = this.appStateListener.minimizedSidebar;
		this.hidden = this.appStateListener.hiddenNavigationInterfaces;

		this.appStateListener.listen((event: {type: string, value: any}) => {
			if(event.type === 'pageTitle') {
				this.currentPageTitle = event.value.raw;
				document.title = this.config.pl_name + ' - ' + event.value.formatted;
			} else if(event.type === 'openedSidebar') {
				this.opened = event.value;
			} else if(event.type === 'minimizedSidebar') {
				this.minimized = event.value;
			} else if(event.type === 'hiddenNavigationInterfaces') {
				this.hidden = event.value;
			} else if(event.type === 'switchEstablishment') {
				this.showSwitchInterface();
			}
		})

		let waitingPromises: Promise<any>[] = [];

		waitingPromises.push(this.systemInfo.getAppInfo().then((appInfo:AppInfo) => {
			this.nativeVersion = appInfo.versionCode+'';
		}).catch(() => {
			return Promise.resolve();
		}));

		waitingPromises.push(this.establishmentRepository.getCurrent().then((establishement: Establishement | null) => {
			if(establishement)
				this.currentEstablishment = establishement;
		}));

		waitingPromises.push(Session.getUserEstablishments().then((partners: ProfileEstablishment[]) => {
			if (partners[0]) {
				this.currentPartner = partners[0];
			}
		}));

		waitingPromises.push(this.establishmentRepository.getUserEstablishments(AuthenticationManager.getUserUid()).then((establishments: ProfileEstablishment[]) => {
			this.userEstablishments = establishments.sort((a, b) => a.establishmentName.localeCompare(b.establishmentName));
			Session.setUserEstablishments(establishments);
		}).catch(() => {
			console.log('Error getUserEstablishments');
		}))

		Promise.all(waitingPromises).then(() => {
			if(this.currentEstablishment) {
				MenuRepository.getSidebarMenu(this.currentEstablishment, this.currentPartner).then((menu: any) => {
					this.navigations = menu;
				});
			}
		});
	}

	getCurrentEstablishmentProfile() {
		if(this.currentEstablishment === null) {
			return null;
		}
		for(let establishment of this.userEstablishments) {
			if(establishment.establishmentUid === this.currentEstablishment.uid) {
				return establishment;
			}
		}
		return null;
	}

	toggleMobileSidebar() {
		let menu = $('#mobilesidebar')
			.sidebar({
				scrollLock: false,
			})
			.sidebar('setting', 'transition', 'pushed')
			.sidebar({
				onHidden: function () {
					$('body').removeClass('pushable');
				}
			})
			.sidebar('toggle');
	}

	close() {
		this.appStateListener.setOpenedSidebar(false);
	}

	showSwitchInterface() {
		this.close();
		if(window.innerWidth > 900) {
			$('#switch-establishment-modal').modal({
				autofocus: false
			}).modal('show')
		} else {
			this.switchEstablishment = true;
		}
	}

	loadSidebar() {

	}
}
