import {VueDestroyed, VueParam, VueVar, VueVirtualComponent, VueWatched} from "horizon-js-front-sdk/lib/VueAnnotate";
import './bottomDrawer.css';

export class BottomDrawerComponent extends VueVirtualComponent {
	// Whether it's open or not
	@VueParam() state!: boolean;
	@VueParam() xShift!: number;
	@VueParam() maxHeight!: number;
	@VueParam() displayClose!: boolean;
	@VueParam() noPadding?: boolean;

	@VueVar() device: "MOBILE" | "TABLET" | "COMPUTER" | null = null;

	@VueWatched()
	stateWatch() {
		if (this.state) {
			$("body").css("overflow", 'hidden');
		} else {
			$("body").css("overflow", 'auto');
			setTimeout(() => {
				this.$emit('close-animation-ended')
			}, 300);
		}
	}

	constructor() {
		super();
		let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
		if (width <= 767) {
			this.device = "MOBILE";
		} else if (width <= 991) {
			this.device = "TABLET";
		} else {
			this.device = "COMPUTER";
		}
	}

	@VueDestroyed()
	destroyed() {
		$("body").css("overflow", 'auto');
	}

	close() {
		this.$emit('bottom-drawer-close');
	}

	static template = /** @lang HTML */`
		<div class="bottom-drawer" :class="{ opened: state, 'no-padding': noPadding }">
			<div class="background" @click.stop="close()"></div>
			<div class="drawer-container" :style="{ bottom: (xShift && device!=='COMPUTER'?xShift:0) + 'px' }">
				<i class="large times icon close-button cursor-pointer" v-if="displayClose === undefined || displayClose" @click="close()"></i>
				<slot></slot>
			</div>
		</div>
	`;
}